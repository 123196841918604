import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const storedTimezone = this.getCookie('browser.timezone');

    if (storedTimezone !== currentTimezone) {
      this.setCookie('browser.timezone', currentTimezone);
    }
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  setCookie(name, value) {
    document.cookie = `${name}=${value}; path=/; max-age=31536000; SameSite=Lax`;

    // make a post request to the server to update the user's timezone
    fetch('/update_timezone', {
      method: 'POST',
      body: JSON.stringify({ timezone: value }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
    });
  }
}