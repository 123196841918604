import { Controller } from "@hotwired/stimulus"
import {install} from '@github/hotkey'

export default class extends Controller {
  initialize() {

    for (const el of document.querySelectorAll('[data-hotkey]')) {
      install(el)
    }
  }
}
