import { Controller } from "@hotwired/stimulus"
import anime from 'animejs/lib/anime.es.js';
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  static values = {
    style: String,
    options: Object,
    finishedAnimating: Boolean
  }

  connect() {
    this.finishedAnimatingValue = false;
    useIntersection(this, { threshold: 0.1 });

    console.log("connected animation");
    if (!this.optionsValue.onAppear) {
      this.animate();
    }
  }

  appear() {
    if (this.optionsValue.onAppear && !this.finishedAnimatingValue) {
      this.animate();
    }
  }

  animate() {
    const animationFunction = this.animationStyles[this.styleValue];
    if (animationFunction) {
      animationFunction.call(this, this.optionsValue);
    }
    this.finishedAnimatingValue = true;
  }

  animationStyles = {
    'slide-from-right': this.slideFromRight,
    'fade-in-up': this.fadeInUp,
    'fade-in': this.fadeIn,
    'slide-in-up-from-right': this.slideInUpFromRight,
    'scale-and-slide-from-right': this.scaleAndSlideFromRight,
    'scale-in': this.scaleIn,
    'pop-in': this.popIn
  }

  commonAnimate(options) {
    return anime({
      targets: this.element,
      duration: options.duration || 800,
      easing: 'easeOutQuad',
      delay: options.delay || 0,
      ...options
    });
  }

  slideFromRight(options) {
    this.commonAnimate({
      translateX: ['100%', 0],
      opacity: [0, 1],
      duration: 1000,
      ...options
    });
  }

  fadeInUp(options) {
    this.commonAnimate({
      translateY: ['100%', 0],
      rotate: options.rotate ? [0, `${options.rotate}deg`] : 0,
      opacity: [0, 1],
      ...options
    });
  }

  fadeIn(options) {
    this.commonAnimate({
      opacity: [0, 1],
      ...options
    });
  }

  slideInUpFromRight(options) {
    this.commonAnimate({
      translateX: ['100%', 0],
      translateY: ['100%', 0],
      ...options
    });
  }

  scaleAndSlideFromRight(options) {
    this.commonAnimate({
      scale: [0, 1],
      translateX: ['100%', 0],
      ...options
    });
  }

  scaleIn(options) {
    this.commonAnimate({
      scale: [0, 1],
      opacity: [0, 1],
      duration: 400,
      ...options
    });
  }

  popIn(options) {
    this.commonAnimate({
      scale: [0.9, 1.05, 1],
      opacity: [0, 1],
      duration: 300,
      easing: 'easeOutBack',
      ...options
    });
  }
}