import { Controller } from "@hotwired/stimulus"
import {Draggable, Droppable, Sortable} from '@shopify/draggable';

export default class extends Controller {
  connect() {
    const draggable = new Draggable(document.querySelectorAll('#tags-list'), {
      draggable: '.tag-draggable',
      distance: 10
    });

    draggable.on('drag:stop', (evt) => {
      // make a post request  to add the tag to the task
      fetch(`/tasks/${document.getElementById("taskActionsId").value}/add_tag`, {
        method: 'POST',
        body: JSON.stringify({tag_id: evt.data.source.dataset.tagId}),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      })
    });
  }
}
