import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = ['content'];

  connect() {
    tippy(this.element, {
      content: 'tooltip',
      arrow: false,
      content: this.contentTarget.content,
      allowHTML: true,
      theme: 'no-padding',
      animation: 'shift-away',
      delay: [this.contentTarget.dataset.tooltipDelay, 0],
      placement: this.contentTarget.dataset.tooltipDirection,
    })

    // this.tooltip = this.createTooltipElement();
    // this.element.appendChild(this.tooltip);
    // this.element.classList.add('relative');
    
    // this.showTooltip = this.showTooltip.bind(this);
    // this.hideTooltip = this.hideTooltip.bind(this);
    // this.element.addEventListener('mouseenter', this.showTooltip);
    // this.element.addEventListener('mouseleave', this.hideTooltip);
    
    // this.hideTooltip();
    // this.tooltipTimeout = null; // Add this line
  }

  disconnect() {
    // this.element.removeEventListener('mouseenter', this.showTooltip);
    // this.element.removeEventListener('mouseleave', this.hideTooltip);
  }

  createTooltipElement() {
    const tooltip = document.createElement('div');
    tooltip.style.transition = 'all 0.2s ease-in-out';
    tooltip.style.opacity = '0';
    tooltip.style.transform = 'translateY(10px)';
    tooltip.classList.add('absolute', 'w-max', 'z-40', 'bottom-0', 'mb-8', 'bg-black', 'border', 'border-neutral-800', 'rounded-md', 'text-neutral-400', 'text-sm', 'rounded', 'px-2', 'py-1');
    tooltip.innerHTML = this.element.dataset.tooltipContent;

    if(this.element.dataset.hotkey){
      tooltip.innerHTML = this.element.dataset.tooltipContent + ' <span class="text-neutral-400 bg-neutral-800 rounded-sm px-1  w-max items-center">' + this.element.dataset.hotkey + '</span>';
    }
    return tooltip;
  }

  showTooltip(event) {
    this.tooltipTimeout = setTimeout(() => {
      this.tooltip.style.opacity = '1';
      this.tooltip.style.transform = 'translateY(0)';
    }, 400);
  }
  
  hideTooltip() {
    clearTimeout(this.tooltipTimeout); // Clear the timeout
    this.tooltip.style.opacity = '0';
    this.tooltip.style.transform = 'translateY(10px)';
  }
}